<template>
  <v-dialog v-model="open" persistent max-width="450px">
    <v-card class="card">
      <v-card-title></v-card-title>
      <v-card-text>
        <v-text-field
          class="mt-2"
          label="姓名 (必填)"
          v-model="name"
          :rules="[rules.required]"
          validate-on-blur
          dense
          outlined
        ></v-text-field>
        <v-text-field
          class="mt-2"
          label="電話 (必填)"
          v-model="phone"
          :rules="[rules.required]"
          validate-on-blur
          dense
          outlined
        ></v-text-field>
        <v-radio-group v-model="delivery" row>
          <v-radio label="店面自取" value="branch"></v-radio>
          <v-radio label="郵寄" value="address"></v-radio>
        </v-radio-group>
        <v-select
          v-if="delivery === 'branch'"
          class="mt-2"
          label="店點 (必填)"
          v-model="branch"
          :items="[
            { text: '請選擇', value: '', disabled: true },
            { text: '文賢店', value: '文賢' },
            { text: '崇善店', value: '崇善' },
            { text: '奇美店', value: '奇美' },
          ]"
          :rules="[rules.required]"
          validate-on-blur
          dense
          outlined
        ></v-select>
        <template v-if="delivery === 'address'">
          <v-text-field
            class="mt-2"
            label="寄件地址 (必填)"
            v-model="address"
            :rules="[rules.required]"
            validate-on-blur
            dense
            outlined
          ></v-text-field>
          <div>
            運費明細
            <div
              v-for="(record, index) in records"
              :key="index"
              class="d-flex justify-space-between"
            >
              <span>{{ index + 1 + ". " + record.rewards.reward }}</span>
              <span>{{ mapIchiban[record.ichibanId]?.fee ?? 200 }}</span>
            </div>
            <p class="text-right">總計: {{ totalFee }} 點</p>
          </div>
        </template>
        <div>
          <v-checkbox
            v-if="!!user.name && !!user.phone"
            class="mt-2 ml-auto mr-0 fit-width"
            label="自動帶入會員資料"
            v-model="autofill"
            dense
            outlined
          ></v-checkbox>
        </div>
        <v-alert type="warning" dense outlined>
          將自動扣除運費，訂單建立後無法手動取消，如需更改需聯繫客服。
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click="open = false" depressed>取消</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="!verified" @click="confirmForm">
          確認
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogDelivery",
  props: ["value", "user", "records", "mapIchiban"],
  data: () => ({
    address: "",
    name: "",
    phone: "",
    delivery: "branch",
    branch: null,
    rules: {
      required: (value) => !!value || "必填欄位",
    },
  }),
  computed: {
    verified() {
      if (this.delivery === "branch") {
        return !!this.branch && !!this.name && !!this.phone;
      }
      return (
        !!this.address &&
        !!this.name &&
        !!this.phone &&
        this.user.balance >= this.totalFee
      );
    },
    open: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    autofill: {
      get() {
        return this.name === this.user.name && this.phone === this.user.phone;
      },
      set(val) {
        if (val) {
          this.name = this.user.name;
          this.phone = this.user.phone;
          this.address = this.user.address;
        }
      },
    },
    totalFee() {
      return this.records.reduce((accFee, record) => {
        const fee = this.mapIchiban[record.ichibanId]?.fee ?? 200;
        return accFee + Number(fee);
      }, 0);
    },
  },
  methods: {
    async confirmForm() {
      try {
        const delivery =
          this.delivery === "branch"
            ? { address: "自取", branch: this.branch }
            : { address: this.address };
        const { data } = await this.axios.post("/ichibanDelivery", {
          user: this.user,
          name: this.name,
          phone: this.phone,
          rewards: this.records.map((record) => record.rewards),
          ichibanRecordIds: this.records.map((record) => record._id),
          ...delivery,
        });

        await this.axios.post(
          `/ichibanDelivery/${data.id}/${this.user.userID}/delivery`,
          {
            fee: this.delivery === "branch" ? 0 : this.totalFee,
          }
        );
        this.$emit("load");
        this.open = false;

        this.$toast.success("成功送出");
      } catch (error) {
        console.log(error);
        this.$toast.error("建立失敗請聯繫客服");
      }
    },
  },
  created() {},
};
</script>
