import { render, staticRenderFns } from "./IchibanDelivery.vue?vue&type=template&id=69771a48&scoped=true&"
import script from "./IchibanDelivery.vue?vue&type=script&lang=js&"
export * from "./IchibanDelivery.vue?vue&type=script&lang=js&"
import style0 from "./IchibanDelivery.vue?vue&type=style&index=0&id=69771a48&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69771a48",
  null
  
)

export default component.exports