<template>
  <IchibanTemplate>
    <template v-slot:body>
      <v-tabs dark background-color="cyan darken-3" show-arrows v-model="tab">
        <v-tabs-slider color="cyan lighten-4"></v-tabs-slider>

        <v-tab tab-value="pending"> 待處理 </v-tab>
        <v-tab tab-value="progress"> 待出貨 </v-tab>
        <v-tab tab-value="sending"> 待收貨 </v-tab>
        <v-tab tab-value="finished"> 已完成 </v-tab>
      </v-tabs>
      <div class="pa-4">
        <template v-if="tab === 'pending'">
          <div class="text-center white--text" v-if="!pendingRecords.length">
            目前無記錄
          </div>
          <v-badge
            v-else
            bordered
            color="cyan"
            :content="select.length"
            overlap
            class="d-block mr-0 ml-auto fit-width"
          >
            <v-btn clock color="cyan" @click="handleDelivery()">
              建立訂單
            </v-btn>
          </v-badge>
          <v-card
            v-for="(row, i) in pendingRecords"
            :key="i"
            color="#d8dee9"
            flat
            outlined
            rounded
            class="mt-1"
          >
            <v-card-title>
              <v-checkbox
                v-model="select"
                label=""
                :value="row._id"
                :key="row._id"
              ></v-checkbox>
              <div>
                {{ row.rewards.reward }}
                <v-chip
                  v-if="row.ichiban?.branch"
                  class="mr-2"
                  color="cyan"
                  text-color="black"
                  small
                >
                  {{ row.ichiban.branch }}
                </v-chip>
              </div>
            </v-card-title>
            <v-card-text>
              <div class="mb-3">
                <h4>{{ row.ichiban?.name }}</h4>
                <div class="body-2">
                  <div>獎項：{{ row.rewards.reward }}</div>
                  <div>郵寄運費：{{ row.ichiban?.fee ?? 200 }}(點)</div>
                  <div>店內自取：免運</div>
                </div>
                <div class="caption text-right">
                  抽獎時間: {{ row.createdAt }}
                </div>
              </div>
            </v-card-text>
          </v-card>
        </template>

        <template v-else>
          <div
            class="text-center"
            v-if="!delivery.filter((row) => [tab].includes(row.status)).length"
          >
            目前無記錄
          </div>
          <v-expansion-panels class="records" v-else>
            <v-expansion-panel
              v-for="(row, i) in delivery.filter((row) =>
                [tab].includes(row.status)
              )"
              :key="i"
              dark
              flat
              outlined
              rounded
              class="mt-1"
            >
              <v-expansion-panel-header>
                <div class="d-flex">
                  <div>
                    <h4 class="mb-2">品項</h4>
                    <li
                      v-for="(reward, index) in row.rewards"
                      :key="index"
                      class="body-2"
                    >
                      {{ index + 1 }}. {{ reward.reward }}
                    </li>
                  </div>
                  <v-spacer></v-spacer>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div>
                  <h4>收件資料</h4>
                  <div class="body-2">
                    <div>收件人：{{ row.name }}</div>
                    <div v-if="row.branch">自取店點：{{ row.branch }}</div>
                    <div v-else>收件地址：{{ row.address }}</div>
                    <div>聯絡電話：{{ row.phone }}</div>
                  </div>
                  <div class="caption text-right">
                    填單時間: {{ row.createdAt }}
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </div>
      <DialogDelivery
        v-if="dialog.delivery"
        v-model="dialog.delivery"
        :user="user"
        :records="selectRecords"
        :mapIchiban="mapIchiban"
        @load="load"
      />
    </template>
  </IchibanTemplate>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import liff from "@line/liff";
import util from "@/mixins/util";
import IchibanTemplate from "./IchibanTemplate.vue";
import DialogDelivery from "@/components/dashboard/ichiban/DialogDelivery.vue";

export default {
  name: "DialogIchiban",
  mixins: [util],
  props: [],
  components: { IchibanTemplate, DialogDelivery },
  data: () => ({
    records: [],
    ichibans: [],
    delivery: [],
    dialog: { delivery: false },
    select: [],
    tab: "pending",
    status: {
      pending: "待處理",
      progress: "處理中",
      finished: "已完成",
      canceled: "已取消",
    },
    statusColor: {
      pending: "success",
      progress: "info",
      finished: "",
      canceled: "error",
    },
  }),
  async created() {
    if (!this.userID) {
      return this.$router.push({
        name: "Ichiban",
        query: { store: "@695yimoc" },
      });
    }

    this.$vloading.show();
    try {
      await Promise.all([this.load(), this.getIchibans()]);
    } catch (error) {
      alert(error);
      this.$router.go(-1);
    }
    this.$vloading.hide();
  },
  computed: {
    ...mapState("clientModule", {
      userID: (state) => state.userID,
      UID: (state) => state.UID,
      user: (state) => state.user,
      token: (state) => state.token,
      store: (state) => state.store,
      images: (state) => state.images,
    }),
    selectRecords() {
      return this.select.map((id) => {
        return this.mapRecord[id];
      });
    },
    pendingRecords() {
      const win = this.records
        .filter((log) => log.rewards.reward)
        .filter((log) => !log.isSelected)
        .map((log) => ({
          ...log,
          ichiban: this.mapIchiban[log.ichibanId],
        }));
      return win;
    },
    ichibanRecordId() {
      return _.keyBy(this.delivery, "ichibanRecordId");
    },
    mapRecord() {
      return _.keyBy(this.records, "_id");
    },
    mapIchiban() {
      return _.keyBy(this.ichibans, "_id");
    },
  },
  methods: {
    async load() {
      this.select = [];
      await Promise.all([
        this.getRecords(),
        this.getDelivery(),
        this.getIchibans(),
        this.getUser(),
      ]);
    },
    async getIchibans() {
      const { data } = await this.axios.get(`/ichiban`);
      this.ichibans = data;
    },
    async getRecords() {
      const { data } = await this.axios.get(`/ichibanRecord`, {
        params: { userID: this.userID },
      });
      this.records = data.reverse();
    },
    async getDelivery() {
      const { data } = await this.axios.get(`/ichibanDelivery`, {
        params: { userID: this.userID },
      });
      this.delivery = data.reverse();
    },
    handleDelivery() {
      if (this.select.length === 0) {
        return this.$toast.error("尚未選取中獎品項");
      }
      this.dialog.delivery = true;
    },
    async getUser() {
      try {
        this.$vloading.show();
        let payload = { store: this.store, token: this.token };
        let { data } = await this.axios.post(`/getUser`, payload);

        this.$store.commit("clientModule/setState", {
          user: data,
        });
      } catch (error) {
        alert(error);
      } finally {
        this.$vloading.hide();
      }
    },
  },
};
</script>
<style lang="scss">
.records.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #d8dee9;
}
</style>

<style lang="scss" scoped></style>
